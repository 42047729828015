export default {
  PUSH_MSG_DATA: "pushMsgData",
  USER_LIST_DATA: "userListData",
  bannedWords:[
    "ㅂ([0-9 ]*)ㅅ|[ㅄᄲᇪᄺᄡᄣᄦᇠ]",
    "[ㅅㅆ]([0-9 ]*)ㅂ|[ᄲᇪᄺᄡᄣᄦᇠ]",
    "ㄱㅐ[넘년놈눔늠새]",
    "[개게]([0-9 ]*)[넘년놈뇬뇸뇽눔늠새색샠샥섹섺셐솩쇅쇗쇸쉐쉑쉨싴싘쌔쌕쎅쒜쒝쒸씹쒯젓젖졎졋좆족좝졎]",
    "[개씨시]?[브부불ㅈ지즈즤쥐]([0-9 ]*)[랄랼럴랠롤뢀]",
    "[개게]([0-9 ]*)[세셋새샛샤스쉐시]([0-9 ]*)[이끼키]",
    "[개게]자[슥싁식지]",
    "걸[래레]가튼[년뇬넘놈뇸]",
    "걸[래레]같은[년뇬넘놈뇸]",
    "[니뉘늬]([0-9]*)[미뮈믜]",
    "띠(이)[바박발방밸벨벌불붕블팔펄풀]",
    "미[륀첫쳤춘췬췽츤칀칑친칧튼틘틩]",
    "[병븅빙]([0-9 ]*)[딱아시싄신싀닥딘띤삼순쉔쉰싡싢싱씬씮탁]",
    "[빽백]?보([0-9 ]*)[오]?[gGzZ지짓짖주즤ㅈ털][ㅣ]?",
    "빨아줄[게까께깨래]",
    "ㅅㅣ[박벨빨팔팍]",
    "[ㅅ색섹쎅쉑][스s]",
    "[시십싯싀씨씹스슈쓔쉬쉽쒸쓉쓰씌ㅅ]([0-9이]*)[8델댕뎅ㅂ바박발방밮밸뱅벵밯밝밠밣뱔뱜봘벌벧벨볼봉불붕블빠빡빨뻘파팍팡팕팛펄풀퐁풍]",
    "[십씹]([0-9]*)[세새쌔쎄]([0-9]*)[꺄끼캬키끼리이]?",
    "[십씹]([0-9]*)[창]",
    "씨[밟밦봙봟봪뵐뷀뷩브빯뽤뽥뿔쁠탱텡팏팗팞팔]",
    "[엠앰][병창]|애[미비자믜븨]",
    "엿[먹같]",
    "[존좉좇졸]([0-9 ]*)[나라]",
    "[졳좃좇죳죴죶죹줟]([0-9]*)[나가까밥]",
    "[패페]티[시쉬걸]",
    "BO[gjz][yi]",
    "[fs]uck",
    "s([0-9 ]*)e([0-9 ]*)x(?!y)",
    "10[넘놈눔밸창챵탱팔]",
    "18[넘년놈눔늠]",
    "18[새세쉑쎄쒜쒝씨]끼",
    "즤랅",
    "지룔",
    "좆",
    "띠랄",
    "씌댈",
    "씌뱌",
    "씌뵹",
    "씌팏",
    "씌팔",
    "씹넘",
    "씹녀",
    "씹년",
    "씹놈",
    "씹뇬",
    "씹뇸",
    "씹떼",
    "씹물",
    "씹쉐",
    "씹쉑",
    "씹알",
    "씹연",
    "씹질",
    "씹탱",
    "씹할",
    "쓰버",
    "쓰뷀",
    "쓰뺄",
    "십녀",
    "십놈",
    "십색",
    "십쉐",
    "십쉑",
    "십알",
    "십질",
    "십탱",
    "십할",
    "개가턴",
    "개가툰",
    "개같은",
    "개걸레",
    "개너마",
    "개노마",
    "개뇨나",
    "개느마",
    "개때꺄",
    "개때끼",
    "개라슥",
    "개부달",
    "개부알",
    "개불알",
    "개샛킹",
    "개샤킥",
    "개소리",
    "개쇗기",
    "개쇠리",
    "개쉐끼",
    "개쉐리",
    "개쉐키",
    "개쉑기",
    "개쉑끼",
    "개쉑키",
    "개쉬리",
    "개싀기",
    "개싀끼",
    "개싀밸",
    "개싀킈",
    "개싀키",
    "개싯기",
    "개싯끼",
    "개싯키",
    "개쌍눔",
    "개쌍늠",
    "개쌍연",
    "개쌍영",
    "개쌔끼",
    "개쌕끼",
    "개쐐리",
    "개쒜리",
    "개쒝갸",
    "개쒝기",
    "개쒝꺄",
    "개쒝끼",
    "개쒝캬",
    "개쒝키",
    "개쒹기",
    "개씨끼",
    "개씨팕",
    "개아이",
    "개염창",
    "개잡년",
    "개잡놈",
    "개잡뇬",
    "개조또",
    "개조옷",
    "개후랄",
    "개후레",
  "구멍에쑤실까",
  "ㄴㅣㅇㅐㅁㅣ",
  "ㄴㅣㅇㅣㅁㅣ",
  "만지면커진다",
  "ㅂㅂㅏ구ㄹㅣ",
  "빨아줄수",
  "빨아주고싶어",
  "빨어핥어박어",
  "ㅅㅏㄱㄱㅏ시",
  "ㅅㅏㄲㅏㅅㅣ",
  "ㅅㅐㄲㅣㅇㅑ",
  "사ㄱㄱㅏㅅㅣ",
  "ㅇㅏㄱㅏㄹㅣ",
  "개ㅅㅐㄲㅣ",
  "개젓가튼넘",
  "게젓가튼넘",
  "그거하고파",
  "그거할사람",
  "나너먹을께",
  "누드스케치",
  "뒤로너어줘",
  "따먹혀줄래",
  "따이고시퍼",
  "또ㄹㅏㅇㅣ",
  "ㅁㅣㅊㅣ넘",
  "몸뚱이줄께",
  "몸안에사정",
  "미국포르노",
  "미스터콘돔",
  "ㅂㅂㅏ구리",
  "ㅂㅕㄴㅌㅐ",
  "밖에다쌀께",
  "발ㅂㅏㅂㅓ",
  "밟ㅇㅏㅂㅓ",
  "배위에싸죠",
  "변GIRL",
  "변YOU녀",
  "ㅃㅏ구ㄹㅣ",
  "뺘아뻐그리",
  "ㅅㅅㅣ벨년",
  "ㅅㅅㅣ불얼",
  "ㅅㅏ까ㅅㅣ",
  "사ㄱㄱㅏ시",
  "사ㄲㅏㅅㅣ",
  "서양이쁜이",
  "섹시팅하자",
  "ㅇㅏㄱㅏ리",
  "ㅇㅑ한사진",
  "일본동영상",
  "일본미소녀",
  "일본뽀르노",
  "젓가튼쉐이",
  "정사씬모음",
  "체위동영상",
  "크리토리스",
  "클리토리스",
  "10새끼",
  "10새리",
  "10쎄끼",
  "10앨넘",
  "10앨놈",
  "10앨늠",
  "10탱아",
  "10할년",
  "ANAL",
  "DAMN",
  "DDUK",
  "GORE",
  "JAJI",
  "JAWE",
  "JAWI",
  "JAZI",
  "JAZY",
  "NOMO",
  "PONS",
  "PONZ",
  "PORN",
  "PRNO",
  "tlqkf",
  "SE엑스",
  "SWAP",
  "X대가리",
  "ZAZI",
  "ㄱㅐㄱㅏ",
  "ㄱㅐㅅㅐ",
  "ㄱㅐ조옷",
  "같이자자",
  "개도라이",
  "개또라이",
  "개쓰래기",
  "개쓰레기",
  "개찌질이",
  "게띠발넘",
  "게섹놈아",
  "게쓰래기",
  "게쓰레기",
  "계약동거",
  "계약애인",
  "그거할남",
  "그거할녀",
  "그거할년",
  "그거할여",
  "그지새끼",
  "그지새키",
  "좃빱",
  "근친상간",
  "글래머샵",
  "꺼어저라",
  "꺼어져라",
  "나를싸게",
  "나를흥분",
  "나먹어줘",
  "넣게벌려",
  "넣고싸고",
  "년의자식",
  "노노무라",
  "누드몰카",
  "누드뮤직",
  "누드사진",
  "누드천사",
  "누드커플",
  "누드클럽",
  "누드필름",
  "누드화보",
  "뉘잉기미",
  "니ㅇㅐ미",
  "니주가리",
  "ㄷㄷㅣ발",
  "ㄷㅏㅂ6",
  "ㄷㅐ추ㄹ",
  "ㄷㅣㅈㅓ",
  "ㄷㅣㅈㅕ",
  "다리벌려",
  "다리벌리",
  "단란주점",
  "대포통장",
  "더러운년",
  "던주꾸마",
  "드러운년",
  "딥닥ㅊㅕ",
  "ㄸㅏㄹ중",
  "딱지떼자",
  "또ㄹㅏ이",
  "똥대가리",
  "똥쳐먹다",
  "러브호텔",
  "리얼에로",
  "ㅁㅣ첸년",
  "ㅁㅣ치넘",
  "ㅁㅣ친넘",
  "만져줄년",
  "머라빠너",
  "머라빠노",
  "무료망가",
  "무료몰카",
  "무료성인",
  "무료야설",
  "무전망가",
  "뮈칀세끼",
  "뮈칀세리",
  "뮈칀섹끼",
  "뮈칀쉐이",
  "뮈친세리",
  "뮈친섹끼",
  "뮈친섹이",
  "뮈친쉑이",
  "미스누드",
  "민셩야설",
  "민증위조",
  "ㅂㅏㅂㅗ",
  "ㅂㅕ잉신",
  "바부탱이",
  "박고빼고",
  "박고싶다",
  "박고싶퍼",
  "박아줄게",
  "박아줄께",
  "발ㅂㅏ부",
  "발ㅂㅏ브",
  "벌렁거려",
  "벌릴여자",
  "변GIR",
  "붐붐고수",
  "빙신쉐리",
  "빙신쉐이",
  "ㅃㅏㄱㅜ",
  "ㅃㅏ구리",
  "ㅃㅏㄹㅏ",
  "빠구ㄹㅣ",
  "빨게해도",
  "빨고시퍼",
  "빨고시포",
  "빨고싶다",
  "빨고싶어",
  "빨구시퍼",
  "빨구싶나",
  "빨구싶어",
  "빨아봐요",
  "ㅅㅏㄲㅏ",
  "ㅅㅏ까시",
  "ㅅㅐㄲㅑ",
  "ㅅㅐㄲㅣ",
  "ㅅㅔㄲㅣ",
  "ㅅㅣ달년",
  "ㅅㅣ입팔",
  "사갈탱이",
  "사ㄲㅏ시",
  "사까ㅅㅣ",
  "색파트너",
  "생양아치",
  "성인누드",
  "성인망가",
  "성인체위",
  "성인화상",
  "성클리닉",
  "성테크닉",
  "세게빨아",
  "세븐섹시",
  "세에엑스",
  "세엑수우",
  "세일러문",
  "세종대왕",
  "섹걸닷컴",
  "섹골닷컴",
  "섹도우즈",
  "섹시나라",
  "섹시누드",
  "섹시뉴스",
  "섹시무비",
  "섹시사진",
  "섹시스타",
  "섹시신문",
  "섹시엔몰",
  "섹시재팬",
  "섹시조선",
  "섹시코디",
  "섹시클럽",
  "섹시클릭",
  "섹시팬티",
  "섹파트너",
  "센스디스",
  "쉬방쉐이",
  "쉬빵세이",
  "쉽생구리",
  "시벨새키",
  "시팍새꺄",
  "시팍새끼",
  "시팔",
  "ㅆㅂㄹㅁ",
  "ㅆㅏㄲㅏ",
  "ㅆㅖㄲㅣ",
  "ㅆㅣ벨년",
  "ㅆㅣ블년",
  "싸고시포",
  "쌩포르노",
  "쒸방쉐이",
  "쒸빵세이",
  "씹셋나루",
  "씹자지년",
  "ㅇㅏ가리",
  "ㅇㅐㅁㅣ",
  "ㅇㅐㅂㅣ",
  "ㅇㅐㅈㅏ",
  "ㅇㅔㅁㅣ",
  "아ㄱㅏ리",
  "아가ㄹㅣ",
  "아쿠아볼",
  "안에사정",
  "알몸공개",
  "알몸사진",
  "애로영화",
  "애마부인",
  "야설신화",
  "야설의문",
  "야시꾸리",
  "야시랭크",
  "야ㅎㅏㄴ",
  "야한게임",
  "야한그림",
  "야한누드",
  "야한만화",
  "야한사딘",
  "야한사진",
  "야한소설",
  "야한영화",
  "야한클럽",
  "얀동영상",
  "양ㅇㅏ치",
  "어리버리",
  "에로69",
  "에로79",
  "에로걸즈",
  "에로게임",
  "에로극장",
  "에로니폰",
  "에로닷컴",
  "에로데이",
  "에로무비",
  "에로바다",
  "에로배우",
  "에로세일",
  "에로시티",
  "에로씨네",
  "에로에스",
  "에로영화",
  "에로팬티",
  "에로필름",
  "엑스모텔",
  "엑스터시",
  "여자먹고",
  "엽기에로",
  "오르가즘",
  "오빠아파",
  "오빠아퍼",
  "옷벗기기",
  "원조알바",
  "유호필름",
  "육구자세",
  "음마성전",
  "음박학염",
  "일본망가",
  "입안사정",
  "입주둥이",
  "ㅈㅏ★우",
  "ㅈㅏㅇㅟ",
  "ㅈㅏ우ㅣ",
  "ㅈㅏㅈㅣ",
  "㉨ㅏ㉨ㅣ",
  "재팬마나",
  "재팬만화",
  "저빠지게",
  "저즌팬티",
  "적셔줄까",
  "적셔줄께",
  "적셔줄래",
  "전나구려",
  "젓빠지게",
  "젓은팬티",
  "정력팬티",
  "정력포탈",
  "정사채널",
  "졎빠",
  "졎은팬티",
  "조둥아리",
  "조옥같네",
  "조옷나게",
  "주둥아리",
  "즐길성인",
  "ㅉㅣㅉㅣ",
  "콘돔닥터",
  "클럽에로",
  "토탈에로",
  "트렘플링",
  "특수콘돔",
  "팰라치오",
  "펠라티오",
  "피임기구",
  "피임용품",
  "한번꽂자",
  "한번줄래",
  "핥아주께",
  "핧아줄께",
  "함대주까",
  "함대줄래",
  "향기콘돔",
  "허쩝새끼",
  "허쩝쌔끼",
  "호모섹기",
  "호모쎄끼",
  "호봉세씨",
  "호봉쎄끼",
  "호봉쒜리",
  "호스트바",
  "호스트빠",
  "홀오자식",
  "화끈물개",
  "후래자식",
  "후레자식",
  "훔쳐보기",
  "흥분할녀",
  "2C8",
  "2C팔",
  "AC발",
  "BO지",
  "JA위",
  "JA지",
  "JCB",
  "JI랄",
  "JOT",
  "NOC",
  "NO예",
  "ⓞ⑥ⓞ",
  "S2X",
  "SⓔX",
  "ⓢEX",
  "⒮⒠⒳",
  "YA설",
  "ZOT",
  "ㄱH뇬",
  "ㄱR슴",
  "가랑이",
  "가스미",
  "가아슴",
  "걔잡넘",
  "걔잡년",
  "걔잡뇬",
  "걸래넘",
  "걸래년",
  "걸래놈",
  "걸레년",
  "게같은",
  "게너마",
  "게노마",
  "게뇨나",
  "게부알",
  "게색기",
  "게색끼",
  "게샛키",
  "게세꺄",
  "게세끼",
  "게셋키",
  "게쉐이",
  "게스끼",
  "게스키",
  "게시끼",
  "게시퀴",
  "게싯끼",
  "게쌔기",
  "게쌕끼",
  "게자시",
  "게잡넘",
  "게잡년",
  "게잡뇬",
  "계약앤",
  "고픈녀",
  "광뇨늬",
  "광뇨니",
  "굴래머",
  "굴레머",
  "궁뎅이",
  "궁둥이",
  "그룹섹",
  "글래머",
  "글레머",
  "기엉아",
  "기집애",
  "기집에",
  "기형아",
  "까발려",
  "까발리",
  "까진년",
  "깜둥이",
  "깝치냐",
  "깝치네",
  "깝친다",
  "깝칠래",
  "깨쌔꺄",
  "깨쌔끼",
  "꺼벙이",
  "꺼어저",
  "꺼어져",
  "꼬라바",
  "꼴리는",
  "꼴리다",
  "끄지라",
  "ㄴㅔ즈",
  "나쁜년",
  "나쁜뇬",
  "내미랄",
  "내미럴",
  "네에미",
  "노브라",
  "노출증",
  "노팬츠",
  "노팬티",
  "누드쇼",
  "누드집",
  "뉘기미",
  "뉘미럴",
  "뉘미롤",
  "뉘이미",
  "뉭기미",
  "느검마",
  "늬귀미",
  "늬기미",
  "늬믜창",
  "니귀미",
  "니기미",
  "니어미",
  "니에미",
  "니에비",
  "니이미",
  "니죠때",
  "니죶데",
  "님이랄",
  "닝기미",
  "ㄷㅣ져",
  "ㄷㅣ진",
  "ㄷㅣ질",
  "닥치셔",
  "대가리",
  "대가빡",
  "대갈빡",
  "대갈통",
  "대주까",
  "대줄년",
  "데져라",
  "데진다",
  "데질래",
  "도그플",
  "도라이",
  "도란년",
  "도른것",
  "도른넘",
  "도른년",
  "도른뇬",
  "도른뇸",
  "도우미",
  "돌림빵",
  "돌은것",
  "돌은년",
  "돌은놈",
  "돌은뇬",
  "돌은뇸",
  "돔만남",
  "돔알지",
  "돔줘요",
  "돼진다",
  "되가리",
  "되질래",
  "뒈져라",
  "뒈진다",
  "뒈질래",
  "뒤길넘",
  "뒤길놈",
  "뒤져라",
  "뒤진다",
  "듀ㄱㅓ",
  "듀글래",
  "드응신",
  "듸진다",
  "디ㅈㅕ",
  "디져라",
  "디진다",
  "디질래",
  "ㄸㅣ발",
  "딱치셔",
  "딸따뤼",
  "딸따리",
  "딸딸이",
  "딸치는",
  "떠라이",
  "떡치기",
  "떨아이",
  "떵싸구",
  "또라이",
  "똘라이",
  "똘른놈",
  "똘뱅이",
  "똘봉년",
  "똘아이",
  "똥구멍",
  "똥꾸녕",
  "똥닦다",
  "뜨건밤",
  "ㄹㅔ즈",
  "로리타",
  "리얼섹",
  "링기미",
  "ㅁL친",
  "ㅁㅣ쳤",
  "ㅁㅣ친",
  "막간년",
  "막간놈",
  "막간뇬",
  "막간뇸",
  "맛간년",
  "맛간놈",
  "맛간뇬",
  "맛간뇸",
  "망가짱",
  "망나니",
  "맞간년",
  "몸파는",
  "몸파라",
  "물확뺄",
  "뭐퀼래",
  "뭐클래",
  "뭬친놈",
  "믜칭넘",
  "미륀놈",
  "미린넘",
  "미린년",
  "미씨촌",
  "미진놈",
  "미진뇬",
  "미찬놈",
  "미첸년",
  "미치넘",
  "미치년",
  "미치뇬",
  "미틴뇬",
  "ㅂㄱ술",
  "㉥㉥ㅏ",
  "ㅂㅓ즤",
  "ㅂㅕㄴ",
  "ㅂㅕ언",
  "바구리",
  "박을께",
  "박을년",
  "발바버",
  "발바부",
  "발바브",
  "밟아버",
  "밟아부",
  "밟아브",
  "백보디",
  "버러지",
  "버지물",
  "버짓물",
  "번개남",
  "벙개남",
  "벙어리",
  "벼잉신",
  "변ㄴㅕ",
  "병엉신",
  "본디지",
  "부카케",
  "붕시나",
  "뷰웅신",
  "브라자",
  "브라쟈",
  "비됴방",
  "비러머",
  "비러먹",
  "비실명",
  "비용신",
  "비웅신",
  "비잉신",
  "빌어먹",
  "빙ㅅㅣ",
  "ㅃㅏ순",
  "빠구리",
  "빠굴이",
  "빠꾸리",
  "빠도리",
  "빠돌이",
  "빠라줘",
  "빠르너",
  "빠수니",
  "빠순이",
  "빤쮸리",
  "빨고파",
  "빨라줘",
  "빨아도",
  "빨아봐",
  "빨아죠",
  "빨아줘",
  "빨자좃",
  "빽자지",
  "뻐르나",
  "뻐르너",
  "뻐르노",
  "뻐어큐",
  "뽀로노",
  "뽀르나",
  "뽀르너",
  "뽀르노",
  "뽀뽀뽀",
  "뽈로노",
  "쀼웅신",
  "삐리넷",
  "ㅅㅏㄱㄱㅏㅅㅣ",
  "ㅅㅏ까",
  "ㅅㅐ꺄",
  "ㅅㅐ뀌",
  "ㅅㅐ끼",
  "ㅅㅔㄱ",
  "ㅅㅔ끼",
  "ㅅㅔ퀴",
  "사ㄲㅏ",
  "사까리",
  "사까시",
  "사까치",
  "사앙뉸",
  "사창가",
  "사카시",
  "사카치",
  "삼룡이",
  "삿깟시",
  "상노무",
  "새ㄲㅑ",
  "새에끼",
  "색ㄲㅣ",
  "색ㅋㅑ",
  "샤불년",
  "샤앙녀",
  "샤앙년",
  "샹악년",
  "샹악연",
  "성교제",
  "성체위",
  "성체험",
  "성추행",
  "성충동",
  "성폭행",
  "성행위",
  "세액스",
  "세에끼",
  "세에쓰",
  "세엑스",
  "섹무비",
  "섹소리",
  "섹시걸",
  "섹시맵",
  "섹시샵",
  "섹시존",
  "섹시짱",
  "섹시촌",
  "섹티쉬",
  "섹티즌",
  "섹하고",
  "섹하구",
  "섹하자",
  "섹하장",
  "섹한번",
  "수타킹",
  "숩삭키",
  "쉑이들",
  "쉣더뻑",
  "쉭셰리",
  "쉽ㅅㅐ",
  "쉽ㅅㅔ",
  "쉽샤퀴",
  "쉽할이",
  "스너프",
  "스쿨걸",
  "스킨쉽",
  "스패킹",
  "스팽킹",
  "싑샤퀴",
  "시글년",
  "시달년",
  "시뎅아",
  "시땡이",
  "시방새",
  "시방세",
  "시방쉐",
  "시벨넘",
  "시부리",
  "시아눔",
  "시앙넘",
  "시앙년",
  "시앙놈",
  "시앙눔",
  "시양넘",
  "시양년",
  "시양놈",
  "시양눔",
  "시이발",
  "시이방",
  "시이밸",
  "시이벌",
  "시이불",
  "시이붕",
  "시이빡",
  "시이팔",
  "시이펄",
  "시이풀",
  "시입팔",
  "신마담",
  "실비아",
  "싫팛늠",
  "십때꺄",
  "십때끼",
  "십떼끼",
  "십셰리",
  "십쇄리",
  "십쇡기",
  "십시끼",
  "십씨키",
  "십좌지",
  "ㅆ1발",
  "ㅆ1팔",
  "ㅆL팔",
  "ㅆㅂㄻ",
  "ㅆㅔㄱ",
  "ㅆㅖ끼",
  "ㅆㅣ발",
  "ㅆㅣ불",
  "싸가리",
  "싸가지",
  "싸발년",
  "싸앙넘",
  "싸앙녀",
  "싸앙년",
  "싸앙눔",
  "싸앙뉸",
  "싸앙늠",
  "싸이커",
  "싸줄께",
  "싹아쥐",
  "싹아지",
  "쌈치기",
  "쌍노무",
  "쌍뇨나",
  "쌍악연",
  "쌔밸년",
  "쌩몰카",
  "쌩아다",
  "쌰갈탱",
  "쌰앙넘",
  "쌰앙녀",
  "쌰앙년",
  "쌰앙눔",
  "쌰앙뉸",
  "쌰앙늠",
  "써글넘",
  "써글년",
  "써글놈",
  "써글눔",
  "써글늠",
  "썩을넘",
  "썩을년",
  "썩을놈",
  "썩을눔",
  "썩을늠",
  "쎄끈한",
  "쎄엑스",
  "쎼ㄲㅣ",
  "쒸댕아",
  "쒸바라",
  "쒸빨넘",
  "쒸이바",
  "쒸이발",
  "쒸이방",
  "쒸이밸",
  "쒸이벌",
  "쒸이부",
  "쒸이불",
  "쒸이붕",
  "쒸이빡",
  "쒸이빨",
  "쒸이팔",
  "쒸이펄",
  "쒸이풀",
  "쒸입새",
  "쓉쎄끼",
  "쓋파라",
  "쓰글넘",
  "쓰글년",
  "쓰글놈",
  "쓰글늠",
  "쓰으방",
  "쓰으벌",
  "쓰이바",
  "쓰이발",
  "쓰이밸",
  "쓰이벌",
  "쓰이불",
  "쓰이붕",
  "쓰이빡",
  "쓰이팔",
  "쓰이펄",
  "씁새끼",
  "씌부려",
  "씨꽈랄",
  "씨땡이",
  "씨레기",
  "씨뤠기",
  "씨방새",
  "씨방세",
  "씨방쉐",
  "씨밸년",
  "씨밸놈",
  "씨벌년",
  "씨벨년",
  "씨봉알",
  "씨부랠",
  "씨부렁",
  "씨부려",
  "씨부리",
  "씨부얼",
  "씨불년",
  "씨불알",
  "씨불얼",
  "씨블년",
  "씨뽀랄",
  "씨앙넘",
  "씨앙년",
  "씨앙놈",
  "씨앙눔",
  "씨양넘",
  "씨양년",
  "씨양념",
  "씨양놈",
  "씨양눔",
  "씨이댕",
  "씨이바",
  "씨이발",
  "씨이방",
  "씨이밸",
  "씨이벌",
  "씨이부",
  "씨이불",
  "씨이붕",
  "씨이빡",
  "씨이빨",
  "씨이팔",
  "씨이펄",
  "씨이풀",
  "씨입년",
  "씨입뇬",
  "씹갓길",
  "씹딱꿍",
  "씹때꺄",
  "씹때끼",
  "씹빵구",
  "씹뽀지",
  "씹색꺄",
  "씹색끼",
  "씹색히",
  "씹샛길",
  "씹생알",
  "씹시끼",
  "씹씨키",
  "에미",
  "ㅇㅐ년",
  "ㅇㅐ미",
  "ㅇㅐ비",
  "ㅇㅐ자",
  "ㅇㅑ설",
  "ㅇㅔ미",
  "아가리",
  "아갈이",
  "아갈통",
  "아구지",
  "아구창",
  "아구통",
  "아굴창",
  "알몸쇼",
  "애널입",
  "애ㅂㅣ",
  "애ㅈㅏ",
  "애자매",
  "앵벌이",
  "야께임",
  "야쉬쉬",
  "야시걸",
  "야시녀",
  "야시룸",
  "야시시",
  "야시캠",
  "야애니",
  "야오위",
  "야요이",
  "야자료",
  "야한거",
  "야한걸",
  "야한것",
  "야한놈",
  "얀예기",
  "얌ㅁㅏ",
  "양아치",
  "얼간이",
  "엄마젓",
  "엉덩이",
  "에니탑",
  "에로관",
  "에로당",
  "에로동",
  "에로물",
  "에로비",
  "에로샵",
  "에로쇼",
  "에로스",
  "에로야",
  "에로올",
  "에로존",
  "에로주",
  "에로카",
  "에미젓",
  "여자몸",
  "염병할",
  "오까마",
  "오성인",
  "올가즘",
  "와레즈",
  "왕가스",
  "왕자지",
  "욧먹어",
  "우라질",
  "우랄질",
  "원초적",
  "육시랄",
  "육시럴",
  "이녀나",
  "이시끼",
  "이자식",
  "임ㅁㅏ",
  "임빙할",
  "입닥처",
  "입닥쳐",
  "ㅈL랄",
  "ㅈㅣ랄",
  "자JI",
  "자슥아",
  "자아지",
  "자지털",
  "잘빨아",
  "잠지털",
  "잡탱년",
  "저까는",
  "저까튼",
  "저꽈튼",
  "저꽛튼",
  "저샤키",
  "저자식",
  "저주글",
  "적가튼",
  "적꽈튼",
  "전나게",
  "전조건",
  "전화방",
  "젓가튼",
  "젓같내",
  "젓같은",
  "젓까는",
  "젓나게",
  "젓마난",
  "젓만이",
  "젓만한",
  "정박아",
  "젖까",
  "젖나",
  "젖도",
  "젖물",
  "젖밥",
  "젖통",
  "젖나",
  "젖가슴",
  "젖가튼",
  "젖같은",
  "젖고픈",
  "젖까는",
  "젖꼭지",
  "젖나게",
  "젖마난",
  "젖만한",
  "젖탱이",
  "져꽈튼",
  "조가비",
  "조가틍",
  "조교사",
  "조까는",
  "조까라",
  "조까치",
  "조까튼",
  "조깐다",
  "조끗네",
  "조刀ㅏ",
  "조삐리",
  "조오까",
  "조오또",
  "조옷나",
  "조쟁이",
  "조지냐",
  "조진다",
  "조질래",
  "조카툰",
  "족간네",
  "족같네",
  "족같은",
  "존ㄴㅏ",
  "존마나",
  "존마난",
  "존마니",
  "존만이",
  "존만한",
  "존망이",
  "존맹구",
  "좀만아",
  "좃간년",
  "좃깟네",
  "좃만아",
  "좄ㄲㅏ",
  "종간나",
  "종아리",
  "죠까틍",
  "죠카태",
  "죠카테",
  "죠카툰",
  "죠콰떼",
  "죠콰태",
  "죠콰테",
  "죠콰퉤",
  "죤만년",
  "죤만놈",
  "주거라",
  "주글년",
  "주길년",
  "주댕이",
  "주둥이",
  "죽은년",
  "죽을년",
  "죽을놈",
  "죽인년",
  "죽일꺼",
  "죽일놈",
  "줴섭다",
  "즐길녀",
  "즐길여",
  "지미럴",
  "질질싸",
  "집어쳐",
  "ㅉㅣ찌",
  "짱나네",
  "쪼가리",
  "쪼ㄲㅏ",
  "쪼까튼",
  "쪼빠라",
  "쪼빤다",
  "쫀ㄴㅏ",
  "찌랄이",
  "찌지리",
  "찌ㅉㅣ",
  "창녀촌",
  "채워줘",
  "처녀막",
  "처머거",
  "처먹어",
  "천재님",
  "첫경험",
  "쳐먹어",
  "쳐죽일",
  "최음제",
  "추ㄴㅕ",
  "캐세끼",
  "캐셋키",
  "컴세엑",
  "케새킹",
  "콘끼그",
  "콩까자",
  "쾌애앰",
  "쿨에로",
  "크림걸",
  "큰자지",
  "트렌스",
  "패니스",
  "퍼르노",
  "페니스",
  "페로몬",
  "페미돔",
  "펩시맨",
  "포로노",
  "포르너",
  "포르노",
  "포르느",
  "포온섹",
  "폰세엑",
  "핥을래",
  "함빨자",
  "핸타이",
  "헤로인",
  "헨타이",
  "헴타이",
  "호냥년",
  "호양년",
  "화끈남",
  "화끈녀",
  "화냥년",
  "화양년",
  "후까시",
  "후다년",
  "후라질",
  "후래아",
  "후리자",
  "후릴년",
  "히로뽕",
  "ⓒⓐ",
  "C댕",
  "C발",
  "C팔",
  "D랄",
  "ⓔ반",
  "G랄",
  "R바",
  "강간",
  "갸숨",
  "갸슴",
  "겁탈",
  "게섹",
  "게젓",
  "계뇬",
  "계뇽",
  "광녀",
  "광년",
  "광뇬",
  "구녕",
  "굴램",
  "그년",
  "까대",
  "깝쳐",
  "깝쵸",
  "깝친",
  "깨쉑",
  "깸비",
  "꺼져",
  "꼴갑",
  "꼴값",
  "꼴갚",
  "꼴깝",
  "꼴려",
  "꼴캅",
  "꼴통",
  "꼽냐",
  "꽁까",
  "끄져",
  "넴병",
  "년놈",
  "년아",
  "노콘",
  "농아",
  "눈깔",
  "니앰",
  "니엠",
  "덥침",
  "덩신",
  "덮침",
  "돌흔",
  "동침",
  "됻밥",
  "둥신",
  "뒈져",
  "뒈지",
  "뒤즬",
  "뒤질",
  "듀거",
  "등띤",
  "등신",
  "등씬",
  "듸랄",
  "듸뢀",
  "듸즬",
  "딍싄",
  "딍신",
  "디랄",
  "디럴",
  "디뢀",
  "디봘",
  "디저",
  "디져",
  "디질",
  "딜도",
  "딥콜",
  "딩구",
  "딩쉰",
  "딩신",
  "딱쳐",
  "딱쵸",
  "딸치",
  "때꺄",
  "떡걸",
  "떡촌",
  "떵개",
  "똥개",
  "똥고",
  "똥꼬",
  "똥물",
  "뛰발",
  "뛰봘",
  "뜁새",
  "뜨발",
  "뜨벌",
  "띄랄",
  "띄발",
  "띄밸",
  "띄뱅",
  "띄벨",
  "띕알",
  "띱때",
  "띱뽕",
  "띱뿡",
  "띱새",
  "띱세",
  "띱쎄",
  "亡가",
  "望가",
  "매춘",
  "매친",
  "매틘",
  "맬섭",
  "멍텅",
  "메둥",
  "메롱",
  "메친",
  "메틘",
  "멜돔",
  "몰카",
  "몸캠",
  "몸켐",
  "뫙가",
  "뭐칀",
  "뮈칀",
  "뮈친",
  "믜칀",
  "믜칑",
  "믜친",
  "믜칭",
  "믜튄",
  "믜틘",
  "믜틴",
  "및친",
  "밑창",
  "밑친",
  "ㅂㅓ",
  "박큐",
  "발넘",
  "발년",
  "밤일",
  "밥팅",
  "배째",
  "밸년",
  "밸놈",
  "뱅쉰",
  "뱅싄",
  "뱅시",
  "뱅신",
  "뱅틴",
  "뱡신",
  "버즤",
  "버케",
  "벅쿠",
  "벅큐",
  "번색",
  "번섹",
  "번쌕",
  "벙섹",
  "벙신",
  "벨년",
  "벨놈",
  "벵쉰",
  "벵싄",
  "벵신",
  "벵틴",
  "벼ㄴ",
  "벼언",
  "변T",
  "변견",
  "변녀",
  "변섹",
  "봉신",
  "봉알",
  "뵝신",
  "뵤즤",
  "뵤지",
  "뵨태",
  "뵹딱",
  "뵹싄",
  "뵹신",
  "불륜",
  "불알",
  "붕딱",
  "붕딲",
  "붕뛴",
  "붕싄",
  "붕신",
  "붕알",
  "붜지",
  "붱싄",
  "붱신",
  "뷍슨",
  "뷍싄",
  "뷍신",
  "뷩쉬",
  "뷩쉰",
  "뷩싄",
  "뷩신",
  "븅틴",
  "블알",
  "븡딱",
  "븡쉰",
  "븡신",
  "븽딱",
  "븽쉬",
  "븽슨",
  "븽싄",
  "븽시",
  "븽신",
  "빙구",
  "빙닥",
  "빙딱",
  "빙뛴",
  "빙띤",
  "빙순",
  "빙쉬",
  "빙쉰",
  "빙싄",
  "빙시",
  "빙신",
  "빙아",
  "빙탁",
  "빙틴",
  "ㅃR",
  "빠걸",
  "빠굴",
  "빠라",
  "빠러",
  "빠큐",
  "빡촌",
  "빡큐",
  "빤스",
  "빤쮸",
  "빨기",
  "빨통",
  "빽큐",
  "뺑신",
  "뺑쒼",
  "뺑씬",
  "뺘큐",
  "뻐큐",
  "뻐킹",
  "뻑가",
  "뻑구",
  "뻑뀨",
  "뻑유",
  "뻑큐",
  "뻘노",
  "뻨규",
  "뻨큐",
  "뼝신",
  "뽀쥐",
  "뽀지",
  "뽀쮜",
  "뽀큐",
  "뽁큐",
  "뽈노",
  "뽕알",
  "뿅신",
  "뿅씬",
  "뿡알",
  "쁑신",
  "삐구",
  "삐꾸",
  "삑구",
  "삑꾸",
  "삥쉰",
  "삥쒼",
  "삥씬",
  "삽입",
  "상넘",
  "상년",
  "상놈",
  "상뇬",
  "새X",
  "새꺄",
  "새뀌",
  "새끠",
  "새뤼",
  "새키",
  "색갸",
  "색골",
  "색광",
  "색기",
  "색꺄",
  "색끼",
  "색남",
  "색녀",
  "색마",
  "색수",
  "색쉬",
  "색쑤",
  "색쓰",
  "색캬",
  "색키",
  "색할",
  "색햐",
  "색히",
  "샊꺄",
  "샙세",
  "샵년",
  "샹넘",
  "샹년",
  "샹논",
  "샹놈",
  "샹뇬",
  "샹늠",
  "세꺄",
  "세뀌",
  "세끠",
  "세끼",
  "세엑",
  "세퀴",
  "세키",
  "섹걸",
  "섹골",
  "섹남",
  "섹녀",
  "섹뜨",
  "섹마",
  "섹수",
  "섹쉬",
  "섹슈",
  "섹쑤",
  "섹쓰",
  "섹할",
  "섹해",
  "섹히",
  "셁기",
  "쇅끼",
  "쇅수",
  "쇅스",
  "쇅쑤",
  "쇅쓰",
  "쇍끼",
  "쇗키",
  "쇼킹",
  "쉐꺄",
  "쉐끼",
  "쉐리",
  "쉐이",
  "쉐키",
  "쉑갸",
  "쉑꺄",
  "쉑수",
  "쉑쑤",
  "쉑쓰",
  "쉑캬",
  "쉑히",
  "쉡세",
  "쉨기",
  "쉨끼",
  "쉨캬",
  "쉨키",
  "쉭빨",
  "쉭뻘",
  "쉭팔",
  "쉻알",
  "쉽새",
  "쉽세",
  "쉽셋",
  "쉽쌔",
  "쉽쎄",
  "쉽알",
  "쉽앨",
  "쉽탱",
  "쉿새",
  "쉿세",
  "습팔",
  "싀방",
  "싀밸",
  "싀벌",
  "싀벨",
  "싀봉",
  "싀팍",
  "싁팍",
  "싑년",
  "싑세",
  "싑알",
  "싑앨",
  "싑얄",
  "싑질",
  "싓팔",
  "식팍",
  "식팔",
  "실밸",
  "싯끼",
  "싯빨",
  "싯팔",
  "싯퐐",
  "싱발",
  "싲팔",
  "싴팔",
  "싵팔",
  "싶알",
  "싶팔",
  "ㅆ엘",
  "싸갈",
  "싸달",
  "싸발",
  "싸뱔",
  "싹년",
  "싹수",
  "쌉년",
  "쌍넌",
  "쌍넘",
  "쌍년",
  "쌍놈",
  "쌍뇬",
  "쌍뇸",
  "쌍뇽",
  "쌍뉸",
  "쌍연",
  "쌔까",
  "쌔꺄",
  "쌔끼",
  "쌔리",
  "쌔캬",
  "쌔키",
  "쌤통",
  "쌩쑈",
  "쌰럽",
  "썁색",
  "썁슥",
  "썁식",
  "썌꺄",
  "써글",
  "써벌",
  "쎄꺄",
  "쎄끈",
  "쎄끼",
  "쎄럽",
  "쎄엑",
  "쎼끼",
  "쎽스",
  "쐉넘",
  "쐉년",
  "쐑갸",
  "쐑꺄",
  "쐑수",
  "쐑스",
  "쐑쑤",
  "쐑쓰",
  "쐑캬",
  "쑤벌",
  "쑵년",
  "쑵새",
  "쒜끼",
  "쒜럽",
  "쒜리",
  "쒝기",
  "쒝끼",
  "쒝수",
  "쒝스",
  "쒝쑤",
  "쒝쓰",
  "쒝캬",
  "쒭새",
  "쒸바",
  "쒸박",
  "쒸발",
  "쒸방",
  "쒸밸",
  "쒸벌",
  "쒸벨",
  "쒸부",
  "쒸불",
  "쒸붕",
  "쒸빠",
  "쒸빡",
  "쒸빨",
  "쒸빵",
  "쒸뻘",
  "쒸파",
  "쒸팍",
  "쒸팔",
  "쒸펄",
  "쒸펠",
  "쒸폴",
  "쒸풀",
  "쒸풍",
  "쓉년",
  "쓉발",
  "쓉새",
  "쓉알",
  "쓉질",
  "쓉창",
  "쓉탱",
  "쓋빨",
  "쓋뻘",
  "쓋팍",
  "씁새",
  "씁알",
  "씦알",
  "씰알",
  "씳박",
  "씳발",
  "씳알",
  "씻끼",
  "씻발",
  "씻벌",
  "씻뻘",
  "씻퐁",
  "씾팔",
  "앂년",
  "앂팔",
  "아갈",
  "아괄",
  "아굴",
  "애무",
  "애액",
  "애쟈",
  "애좌",
  "야껨",
  "야녀",
  "야덩",
  "야동",
  "야똥",
  "야설",
  "야섹",
  "야캠",
  "얌마",
  "양년",
  "양아",
  "어벙",
  "엄창",
  "에로",
  "에믜",
  "연병",
  "연븅",
  "염병",
  "염뵹",
  "영계",
  "옘병",
  "오랄",
  "오럴",
  "왕따",
  "요년",
  "욘병",
  "욘뵹",
  "욘븅",
  "욤뵹",
  "월경",
  "유두",
  "유방",
  "육갑",
  "육봉",
  "윤간",
  "윤락",
  "윤븅",
  "음경",
  "음란",
  "이년",
  "이뇬",
  "자궁",
  "자위",
  "자쥐",
  "자지",
  "잡것",
  "잡년",
  "잡놈",
  "잡뇬",
  "잡뇸",
  "잡밥",
  "잦털",
  "재랄",
  "저까",
  "저년",
  "저놈",
  "저뇬",
  "저빱",
  "젇놔",
  "점물",
  "접밥",
  "젓까",
  "젓나",
  "젓밥",
  "정력",
  "정액",
  "젙니",
  "조까",
  "조깥",
  "조끗",
  "조또",
  "조랄",
  "조뢀",
  "조빠",
  "조빱",
  "조털",
  "족까",
  "족딸",
  "족밥",
  "존년",
  "존니",
  "존만",
  "졷까",
  "졷따",
  "좀물",
  "좁년",
  "좁밥",
  "좁빱",
  "좃넘",
  "좃니",
  "좃털",
  "좄까",
  "종나",
  "죄랄",
  "죤나",
  "죤니",
  "죨라",
  "죵나",
  "죵늬",
  "질알",
  "질할",
  "ㅉㅈ",
  "짜샤",
  "짜쌰",
  "짜져",
  "짜쥐",
  "짜지",
  "짬지",
  "짭것",
  "짭년",
  "짭놈",
  "짭뇬",
  "짱나",
  "째려",
  "쩌껀",
  "쩝밥",
  "쩤밥",
  "쪼갈",
  "쪼건",
  "쪼까",
  "쪼껀",
  "쪼다",
  "쫀나",
  "쫀물",
  "쫍밥",
  "쫍빱",
  "쫒같",
  "쭈욱",
  "쭈쭈",
  "찌랄",
  "찌찌",
  "찐따",
  "창남",
  "창녀",
  "창년",
  "창뇨",
  "창뇬",
  "창여",
  "챵녀",
  "챵년",
  "챵뇬",
  "청부",
  "체위",
  "초칠",
  "촌년",
  "촌놈",
  "카섹",
  "컴샷",
  "컴섹",
  "컴쎅",
  "콘필",
  "콩까",
  "쾌앰",
  "쾌엠",
  "퇴폐",
  "팸돔",
  "팸섭",
  "퍽큐",
  "펀색",
  "펀섹",
  "펜섭",
  "펜썹",
  "펨돔",
  "펨섭",
  "포경",
  "포노",
  "폰떽",
  "폰색",
  "폰섹",
  "폰쉑",
  "폰쌕",
  "폰팅",
  "풋잡",
  "항문",
  "허뎝",
  "허벌",
  "허접",
  "허좁",
  "호로",
  "호좁",
  "홀닥",
  "후장",
  "ㅑ동",
  "뇬",
  "샠",
  "썅",
  "쎅",
  "씝",
  "凸",
  "MASTURBATION",
  "CUNNILINGUS",
  "FETISHWOMAN",
  "ONECLLCKPAY",
  "SYLIVASAINT",
  "FANTADREAM",
  "HOSTMASTER",
  "SORASGUIDE",
  "ASIANGIRL",
  "BEHEADING",
  "FEALLATIO",
  "PENTHOUSE",
  "SORAGUIDE",
  "AWOODONG",
  "BBAGOORI",
  "GANGBANG",
  "GANGSHAQ",
  "PHONESEC",
  "SPANKING",
  "ASSHOLE",
  "BBAGURY",
  "BYUNTAE",
  "COMESEK",
  "ECSTASY",
  "EROASIA",
  "EROCINE",
  "EROSIAN",
  "FETISH7",
  "LESBIEN",
  "NALLARI",
  "NOPANTS",
  "PLAYBOY",
  "SUICIDE",
  "SWAPING",
  "4KKASI",
  "BUNSEC",
  "BUNSEK",
  "CCIPAL",
  "CCIVAL",
  "COMSEK",
  "FEMSUB",
  "FETISH",
  "HENTAI",
  "JGIRLS",
  "KGIRLS",
  "NIGIMI",
  "PENNIS",
  "PETISH",
  "PHONE색",
  "PNNANG",
  "PONSEC",
  "PONSEK",
  "PORNER",
  "SSIBAL",
  "SSIPAL",
  "SSIVAL",
  "YADONG",
  "YASINE",
  "10JIL",
  "BINYA",
  "BITCH",
  "CIBAL",
  "CIPAL",
  "CIVAL",
  "DILDO",
  "DOG새끼",
  "DOUMI",
  "ERO69",
  "HOMPY",
  "JAMJI",
  "JASAL",
  "JIRAL",
  "PENIS",
  "PONDO",
  "PORNO",
  "SIBAL",
  "SIPAL",
  "SIVAL",
  "S하E자X",
  "THEME",
  "VULVA",
  "ZASAL",
  "ZOTTE"
  ]
};