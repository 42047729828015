<template>
    <div class="scroll" @scroll="handleScroll">
    <chat-message
      v-for="(msg, index) in msgs"
      :key="index"
      :msg="msg"
      :maskedLength="maskedLength"
      :custNo="custNo"
    >
    </chat-message>
  </div>
</template>

<script>
import ChatMessage from "@/components/ChatMessage";
import '@/styles/style.css';
export default {
  components: {
    ChatMessage,
  },
  props: ["msgs","maskedLength", "custNo"],
  methods: {
    handleScroll(event) {
      const chatList = event.target;
      const scrollTop = chatList.scrollTop;
      const scrollHeight = chatList.scrollHeight;
      const clientHeight = chatList.clientHeight;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1; // 스크롤이 맨 아래인지 체크

      // 스크롤 이벤트 데이터를 부모 컴포넌트로 emit
      this.$emit('scroll', { scrollTop, isAtBottom });
    }
  }
};
</script>

<style>

/* .chat__body {
  padding: 2rem;
  overflow: auto;
  scroll-behavior: smooth;
}

.chat__body::-webkit-scrollbar {
  display: none;
} */
</style>
