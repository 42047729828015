<template>
    <div>
        404 Not Found
        <h2>페이지를 찾을 수 없습니다.</h2>
    </div>
</template>
<script>

export default {
  data() {
    return {
    };
  },
  mounted() {
  },
 
};
</script>

<style scoped>
</style>