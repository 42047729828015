import Constant from "../../Constant";

const state = {
  msgData: [],
  userListData: []
};

const getters = {};

const actions = {};

const mutations = {
  [Constant.PUSH_MSG_DATA]: ($state, $payload) => {
    $state.msgData.push($payload);
  },
  [Constant.USER_LIST_DATA]: ($state, $payload) => {
    $state.userListData = $payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
