<template>
  <!-- 본인 메시지 -->
  <div v-if="msg.admin=== false && isMe===true"
    class="chat__mymessage message right"
    :class="'chat__first'">
    <div class="box">
      <span class="nick">  {{msg.name}}</span>
      <p class="chat__mymessage__paragraph">{{ msg.msg }}</p>
    </div>
  </div>

  <!-- 관리자 메시지 chat__yourmessage  -->
  <div
    v-else-if="msg.admin === true && msg.reply === false"
    class="message left chat" :class="'chat__first'">
    <div class="box">
      <span class="chat__adminmessage nick">
        {{ msg.adminName }}
      </span>
        <p class="chat__adminmessage__paragraph">
          {{ msg.adminMsg }}
        </p>
        <!-- <p class="chat__yourmessage__time">23:38</p> -->
    </div>
  </div>

  <!-- 관리자 답변 메시지 -->
  <div 
    v-else-if="msg.reply === true" class="message left chat">
    <div class="box">
      <span class="nick">{{ maskedNickName(msg.name)}}</span>
	  	<p>  {{ msg.msg }}</p>
		</div>
		<div class="box reply">
			<span class="nick">{{ msg.adminName }}</span>
			<p>{{ msg.adminMsg }}</p>
		</div>
	</div>

  <!-- 다른 유저 메시지 -->
  <div v-else
    class="chat__yourmessage message left" :class="'chat__first'">
    <div>
      <p class="chat__yourmessage__user nick">
        {{ maskedNickName(msg.name) }}
      </p>
      <div class="chat__yourmessage__p">
        <p class="chat__yourmessage__paragraph">
          {{ msg.msg }}
        </p>
          <!-- <p class="chat__yourmessage__time">23:38</p> -->
      </div>
    </div>
  </div>

</template>

<script>
import '@/styles/style.css';
export default {
  props: ["msg", "maskedLength","custNo"],
  data() {
    return {
      isMe: false,
      
      //isSame: false,
      //avatar: require("../assets/avatar.svg"),
    };
  },
  methods: {
    maskedNickName(name) {
      // 닉네임이 3글자인경우 2자리만 마스크
      let hideCharacters = this.maskedLength;
      if(name.length <=hideCharacters){
        hideCharacters = name.length-1
        
      }
      if(hideCharacters<0)
         hideCharacters =0;

      const masked = name.slice(0, (name.length - hideCharacters)) + '*'.repeat(hideCharacters);
      return masked;
    },
    isSamePerson(msg) {
      if(msg.admin== false && msg.custNo ==this.custNo){
        return true
      }
      return false
    },
  },
  created() {
    this.isMe = this.isSamePerson(this.msg);
    // if (this.msg?.avatar) {
    //   this.avatar = this.msg?.avatar;
    // }
  },
};
</script>

<style>
/* .chat__mymessage {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  margin: 0;
  min-height: 50px;
  line-break: anywhere;
}

.chat__mymessage__paragraph {
  margin: 0.4rem 0 0 1rem;
  border-radius: 20px 20px 0px 20px;
  max-width: 180px;
  background-color: #bbefe6;
  color: #ffffff;
  padding: 0.8rem;
  font-size: 14px;
}

.chat__first {
  margin-top: 2rem;
}

.chat__yourmessage {
  display: flex;
}

/* .chat__yourmessage__avartar {
  width: 40px;
  margin-right: 1rem;
} */

/* .chat__yourmessage__img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
} */

/* .chat__yourmessage__user {
  font-size: 14px;
  font-weight: 700;
  color: #292929;
  margin-top: 0;
  margin-block-end: 0rem;
}

.chat__yourmessage__p {
  display: flex;
  align-items: flex-end;
  line-break: anywhere;
}

.chat__yourmessage__paragraph {
  margin: 0.4rem 1rem 0 0;
  border-radius: 0px 20px 20px 20px;
  background-color: #f3f3f3;
  max-width: 180px;
  color: #414141;
  padding: 0.8rem;
  font-size: 14px;
}

.chat__adminmessage {
  font-size: 15px;
  font-weight: 700;
  color: #0572b6;
  margin-top: 0;
  margin-block-end: 0rem;
}

.chat__adminmessage__p {
  display: flex;
  align-items: flex-end;
  line-break: anywhere;
}

.chat__adminmessage__paragraph {
  margin: 0.4rem 1rem 0 0;
  border-radius: 0px 20px 20px 20px;
  background-color: #def4f4;
  max-width: 180px;
  color: #000000;
  padding: 0.8rem;
  font-size: 14px;
}

.chat__yourmessage__time {
  margin: 0;
  font-size: 12px;
  color: #9c9c9c;
} */ 
</style>
