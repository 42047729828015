import Vue from "vue";
import VueRouter from "vue-router";

import Chat from "../views/Chat.vue";
import NoService from "../views/NoService.vue";
import notFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  
  // {
  //   path: "/chat/:id",
  //   name: "chat",
  //   component: Chat
  // },
  {
    path: "/chat",
    name: "chat2",
    component: Chat
  },
  {
    path: "/noservice",
    name: "noService",
    component: NoService
  },
  {
    path: "/login",
    component: () => import("@/views/login.vue"),
    beforeEnter() {
      window.location.href = "https://m.hnsmall.com/customer/login?return_url=hns.atum.live/chat";
      //window.location.href = "https://mp1.hnsmall.com/customer/login?return_url=hns.atum.live/chat";
      //window.location.href = "https://mp3.hnsmall.com/customer/login?return_url=hns.atum.live/chat";
    },
  },
  {
    path: "*",
    name: "404",
    component: notFound
  }
 
  // {
  //   path: "/login",
  //   component: Chat,
  //   beforeEnter(to, from, next){
  //     window.location.href = "https://m.hnsmall.com/customer/login?return_url=hns.atum.live"
  //   }
  // }
];

const router = new VueRouter({
  mode: "history",
  //base: process.env.BASE_URL,
  routes,
});

export default router;
