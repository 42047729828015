<template>
  <!-- <transition name="modal"> -->
    <!-- <div class="modal-mask"> -->
        <div class="layerPopWrap common-pop pop-nickname-field open" >
        <!-- <div class="modal-wrapper"> -->
            <div class="layerHeader">
            <!-- <div class="modal-container"> -->
                <!-- <div class="modal-header"> -->
                <slot name="header"> default header</slot>
            </div>
                <!-- </div> -->

                <!-- <div class="modal-body"> -->
                    <div class="layerConts">
                    <slot name="body"> default body</slot>
                </div>

                <!-- <div class="modal-footer">
                    <button type="button" class="btnLayerClose" @click="$emit('close')">
                        <span>확인</span>
                    </button>
                </div> -->
                <div class="btnWrap">
                    <button class="btnConfirm black" @click="$emit('close')">확인</button>
	            </div>
    </div>
   
  <!-- </transition> -->
</template>

<script>

export default {}
</script>


<style>
@import '@/styles/style.css';
.layerPopWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.5); */
  position: fixed;


  left: 50%;
  top: 40%;
  width: 295px;
   background-color: #fff;  
  z-index: 5002;
    border-radius: 16px;
    transform: translateX(-50%);
    overflow: hidden;
}

  /* 그 외에 원하는 스타일을 추가할 수 있습니다. */
 /* .modal-mask{
    position: fixed;
    z-index:9999;
    top:0;
    left:25%;
    width:50%;
    height:50%;
    background-color: rgba(0,0,0,0);
    display: table;
    transition: opacity 0.3s ease;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.modal-container {

    margin: 0px auto;
    padding:20px 20px;
    background-color: #fff;
    border-radius: 1px;
    box-shadow: 0 2px 8px rgba(0,0,0, 0.33);
    transition: all 0.3s ease;
    font-family: Arial, Helvetica, sans-serif
}

.modal-header h3 {
    margin-top: 0;
    color: #131514;
}
.modal-body{
    margin:20px 0;
}
.modal-enter{
    opacity:0;
}
.modal-enter .modal-container, .modal-leave-active .modal-container{
    -webkit-transform: scale(1.1);
    transform:scale(1.1);
}
.modal-body,
.modal{
    color:#666 !important;
}  */

</style>
