<template>
    <div>
        <h2>Not in service.</h2>
    </div>
</template>
<script>

export default {
  data() {
    return {
    };
  },
  mounted() {
  },
 
};
</script>

<style scoped>
</style>