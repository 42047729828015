<template>
    <router-view  />
</template>

<script>
export default {
  //mounted() {
    // window.addEventListener('popstate',this.handlePopState);
  //},
  beforeDestroy(){
    // window.removeEventListener('popstate',this.handlePopState);
  },
  methods: {
    // handlePopState(event){
    //   console.log('popstate event : ', event);
    //
  },
};
</script>

<style>
 @import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");
/* @font-face {
  font-family: "Golden Plains - Demo";
  src:
   url("./assets/GoldenPlains.woff2") format("woff2"),
    url("./assets/GoldenPlains.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

* {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
}

body {
  background-color: #cfcff4;
  overflow:hidden;
  height:100%;
}

.container {
  background-color: #ffffff;
  border-radius: 0.5rem;
  /*box-shadow: 0px 1px 20px #9c9cc855;*/
  padding-bottom:3px;
  padding-left:3px;
  padding-right:3px;
  padding-top:3px;
}
</style>
