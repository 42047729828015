import Vue from "vue";
import io from "socket.io-client";
//10.10.1.234
//const socket = io("10.10.1.209:4302");
// const socket = io({
//   transports: ['websocket'],
//   reconnection:false
// });
const socket = io({
  transports: ['websocket'],
  reconnection:true
});
//const socket = io("13.124.113.132:4302")

const SocketPlugin = {
  install(vue) {
    vue.mixin({});
    vue.prototype.$connect = () =>{
      socket.connect()
    }
    vue.prototype.$disconnect = () =>{
      socket.disconnect()
    };
    vue.prototype.$join = ($payload) => {
      socket.emit("join", $payload);
    };
    vue.prototype.$checkUserName = async ($payload) =>{
      await socket.emit("checkUserName",$payload)
    }
    vue.prototype.$leave = ($payload) => {
      socket.emit("leave", $payload);
    };

    vue.prototype.$changeName = ($payload) => {
      socket.emit("changeName", $payload)
    };
    vue.prototype.$sendMessage = ($payload) => {
      socket.emit("chat", {
        msg: $payload.msg,
        name: $payload.name,
        //avatar: $payload.avatar,
      });
    };
    
    vue.prototype.$socket = socket;
  },
};

Vue.use(SocketPlugin);
