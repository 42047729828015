<template>
    <span class="time">{{time}}</span>
</template>

<script>
import '@/styles/style.css';
export default {

  data() {
    return {
      time: ''
    }
  },
  props: ["startTime", "endTime"],
  mounted() {
    setInterval(() => this.getRemainTime(), 1000)
  },
  methods: {
    getRemainTime () {
      const now = new Date()
      const endDate = new Date(this.endTime)
      const startDate = new Date(this.startTime)
      const timeRemaining = endDate - now
      const timeBeforeStart = now - startDate
      if (timeRemaining <= 0) {
    	  this.time = "방송종료";
      	return;
      }
      if(timeBeforeStart <=0){
        this.time = "방송종료";
      	return;
      }
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      this.time = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    }   
  }
}
</script>