<template>
	<div class="pop-livetalk-header">
		<h1>홈앤 라이브톡</h1>
		<!--<span class="time">00:38:53</span>-->
    <clock :startTime="startTime" :endTime="endTime"></clock>
		<!-- <a href="#none" class="btn-close">닫기</a>-->
    <a href="hnsmallapp://webcontrol?action=typeViewClose" class="btn-close">X</a>
	</div>
</template>

<script>
import clock from '@/components/RemainClock'
import '@/styles/style.css';
export default {
  components: {
    clock,
  },
  data() {
    return {
      playingTime : "",
    }
  },
  methods: {
    leaveChat(){
    
      //window.history.back();
      //window.open('hnsmallapp://webcontrol?action=typeViewClose','_self','')
      //window.close()
  }
  },
  props: ["startTime","endTime"]
}
</script>